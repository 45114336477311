<template>
    <div>
        <ConfirmationDialog v-bind="confirmationDialogParameters"
                            v-on:delete-package="deletePackage"/>

        <RecordDialog v-if="showRecordDialog" v-bind="recordDialogParameters"
                      v-on:hidden="showRecordDialog = false"
                      v-on:ok="setData"/>

        <!-- DOCUMENT HEADER -->

        <div class="card st-framework-card" v-if="pageLoaded">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 280px; border: solid 1px lightgray;">
                            <font-awesome-icon :icon="icon" size="sm" class="mr-2"/>
                            <template v-for="(action,index) in actions.manageActions">
                                <SelectAction :key="action.name + '-' + index"
                                              :action="action" :disabled="state.disabled"
                                              v-on:select-view="selectView"
                                              v-if="action.type === 'SELECT' && action.placement === 'DOCUMENTHEADERLEFT'"/>
                            </template>
                        </td>
                        <td style="width: 1rem;"></td>
                        <td style="margin: 0; padding: 0 10px; border: solid 1px lightgray;">
                            <SelectField :field="fields.selectField" v-on:input="documentSelectInput"/>
                        </td>
                        <td style="margin: 0; padding: 0; width: 90px; text-align: right;">(#{{ data.id }})</td>
                        <td style="width: 1rem;"></td>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0">
                <div class="row no-gutters">
                    <!-- DOCUMENT CARD -->

                    <div class="col-4">
                        <div class="card">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.' + document, 1) }}
                                    <template v-for="(action,index) in actions.manageActions">
                                        <DropDownAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                                        v-on:download="download($event)"
                                                        v-if="action.placement === 'DOCUMENTCARDHEADER' && action.type === 'DROPDOWN'"/>
                                    </template>
                                </b-form>
                            </div>
                            <div class="card-body">
                                <QrCode :value="data.qr_code"/>
                            </div>
                        </div>
                    </div>

                    <!-- PROJECT CARD -->

                    <div class="col-4">
                        <div class="card">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.Project', 1) }}
                                </b-form>
                            </div>
                            <div class="card-body">
                                <table class="table table-borderless table-sm" style="margin-bottom: 0">
                                    <tbody>
                                    <tr>
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Number') }}</b></td>
                                        <td class="p-0">{{ data.project_id.id }}</td>
                                    </tr>
                                    <template v-for="(action,index) in actions.manageActions">
                                        <template v-if="action.placement === 'PROJECTCARDBODY' && action.type === 'LINK' && action.name === 'link-project'">
                                            <tr :key="'project-' + action.name + '-' + index">
                                                <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Name') }}</b></td>
                                                <td class="p-0">
                                                    <LinkAction :action="action"></LinkAction>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    <tr v-if="data.project_id.reference">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Reference') }}</b></td>
                                        <td class="p-0">{{ data.project_id.reference }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.manager">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Manager') }}</b></td>
                                        <td class="p-0">{{ data.project_id.manager }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.correspondent">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Correspondent') }}</b></td>
                                        <td class="p-0">{{ data.project_id.correspondent }}</td>
                                    </tr>
                                    <tr>
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.tc('translations.Project Status', 1) }}</b></td>
                                        <td class="p-0">{{ data.project_id.project_status_id.label }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- CUSTOMER CARD -->

                    <div class="col-4">
                        <div class="card">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.Customer', 1) }}
                                </b-form>
                            </div>
                            <div class="card-body">
                                <table class="table table-borderless table-sm" style="margin-bottom: 0">
                                    <tbody>
                                    <tr v-if="data.project_id.customer_id.full_name">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Name') }}</b></td>
                                        <td class="p-0">{{ data.project_id.customer_id.full_name }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.customer_id.company">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Company') }}</b></td>
                                        <td class="p-0">{{ data.project_id.customer_id.company }}</td>
                                    </tr>
                                    <tr v-if="data.project_id.customer_id.street || data.project_id.customer_id.city || data.project_id.customer_id.country">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Address') }}</b></td>
                                        <td class="p-0">
                                            <span v-if="data.project_id.customer_id.street">{{ data.project_id.customer_id.street }} {{ data.project_id.customer_id.number }}<br></span>
                                            <span v-if="data.project_id.customer_id.city">{{ data.project_id.customer_id.postal }} {{ data.project_id.customer_id.city }}<br></span>
                                            <span v-if="data.project_id.customer_id.country">{{ data.project_id.customer_id.country }}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="data.project_id.customer_id.vat">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.VAT') }}</b></td>
                                        <td class="p-0">{{ data.project_id.customer_id.vat }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PACKAGES HEADER -->

                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col">
                                {{ packages ? packages.length : '0' }} {{ $i18n.tc('translations.Package', (packages ? packages.length : 0)) }}
                            </div>
                            <div class="col-auto">
                                <template v-for="(action,index) in actions.manageActions">
                                    <ButtonAction :key="action.name + '-' + index" :disabled="state.disabled" :action="action"
                                                  v-on:create-package="createPackage"
                                                  v-if="action.placement === 'GROUPHEADERRIGHT' && action.type === 'BUTTON'"/>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PACKAGES -->

                <template v-if="data.packages">
                    <template v-for="(p,index) in data.packages">
                        <div :key="'package-' + index" class="row no-gutters" v-if="index % 3 === 0">
                            <template v-for="n in 3">
                                <div :key="'package-' + index + '-' + n" class="col-4" v-if="data.packages[index + n - 1]">
                                    <div class="card">
                                        <div class="card-header light">
                                            <b-form inline>
                                                {{ $i18n.tc('translations.Package') + ': ' + data.packages[index + n - 1].name }}
                                                <template v-for="action in actions.manageActions">
                                                    <ButtonAction :key="action.name + '-' + index + '-' + n" :disabled="state.disabled" :action="action"
                                                                  v-on:delete-package="confirmDeletePackage(index + n - 1)"
                                                                  v-if="action.placement === 'ELEMENTHEADER' && action.type === 'BUTTON' && action.name === 'delete-package'"/>
                                                    <ButtonAction :key="action.name + '-' + index + '-' + n" :disabled="state.disabled" :action="action"
                                                                  v-on:update-package="updatePackage(index + n - 1)"
                                                                  v-if="action.placement === 'ELEMENTHEADER' && action.type === 'BUTTON' && action.name === 'update-package'"/>
                                                </template>
                                            </b-form>
                                        </div>
                                        <div class="card-body">
                                            <p v-html="data.packages[index + n - 1].content"/>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog";
import DropDownAction from "@/components/actions/DropDownAction";
import FileDownload from "@/mixins/FileDownload";
import LinkAction from "@/components/actions/LinkAction";
import QrCode from "@/components/QrCode";
import RecordDialog from "@/components/dialogs/RecordDialog";
import SelectField from "@/components/forms/fields/SelectField";
import SelectAction from "@/components/actions/SelectAction";

export default {
    name: "PickUpReceipt",
    components: {
        SelectAction,
        ButtonAction,
        ConfirmationDialog,
        DropDownAction,
        LinkAction,
        QrCode,
        RecordDialog,
        SelectField,
    },
    props: ['name', 'document', 'icon', 'api', 'state', 'userLanguage', 'languages'],
    mixins: [FileDownload],
    data() {
        return {
            pageLoaded: false,
            data: null,
            showDocumentCardFooter: true,
            packages: null,
            actions: null,
            fields: null,
            confirmationDialogParameters: {
                type: '',
                title: '',
                message: '',
                ok_event: 'ok',
                ok_text: 'OK',
                cancel_event: 'cancel',
                cancel_text: this.$i18n.t("translations.Cancel"),
                data: null,
            },
            showRecordDialog: false,
            recordDialogParameters: {
                type: null,
                title: null,
                api: null,
                fields: null,
                data: null,
                languages: this.languages,
                userLanguage: this.userLanguage,
            },
        }
    },
    watch: {
        $route() {
            this.fetchData(parseInt(this.$route.params.id));
        }
    },
    created() {
        this.fetchData(parseInt(this.$route.params.id));
    },
    methods: {
        checkLayout() {
            this.showDocumentCardFooter = false;
            this.actions.manageActions.forEach(action => {
                if (action.placement === 'DOCUMENTCARDFOOTERLEFT' || action.placement === 'DOCUMENTCARDFOOTERRIGHT') {
                    this.showDocumentCardFooter = true;
                }
            });
        },
        confirmDeletePackage(index) {
            this.confirmationDialogParameters.type = 'delete';
            this.confirmationDialogParameters.title = this.$i18n.t("translations.Delete Package") + ': ' + this.packages[index].name;
            this.confirmationDialogParameters.message = this.$i18n.t("translations.Are you sure you want to delete this package?");
            this.confirmationDialogParameters.ok_event = 'delete-package';
            this.confirmationDialogParameters.ok_text = this.$i18n.t("translations.Delete");
            this.confirmationDialogParameters.cancel_text = this.$i18n.t("translations.Cancel");
            this.confirmationDialogParameters.data = {index: index};
            this.$bvModal.show('confirmation-dialog');
        },
        createPackage(data) {
            this.state.loading = true;
            if (!this.packages) {
                this.packages = [];
            }
            for (let i = 0; i < data.quantity; i++) {
                this.packages.push({name: this.getNextName(), content: null});
            }
            this.updateData();
        },
        deletePackage(data) {
            this.state.loading = true;
            this.packages.splice(data.index, 1);
            if (!this.packages.length) {
                this.packages = null;
            }
            this.updateData();
        },
        documentSelectInput(data) {
            if (data['id'] !== parseInt(this.$route.params.id)) {
                this.$router.push({params: {id: data['id']}});
            }
        },
        download(choice) {
            this.state.loading = true;
            let categoryIds = {};
            this.$http.post(choice.api + '/' + this.data.id, {'categoryIds': categoryIds}, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(choice, res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("PickUpReceipt:download():error:", error);
            });
        },
        fetchData(id) {
            this.state.loading = true;
            this.pageLoaded = false;
            this.$http.get(this.api + '/manage/' + id, {}).then((res) => {
                this.setData(res.data);
                this.pageLoaded = !!this.data;
                this.state.loading = false;
            }).catch((error) => {
                console.log("PickUpReceipt:fetchData():error:", error);
            });
        },
        getNextName() {
            let valid = true;
            let next = 0;
            do {
                valid = true;
                next++;
                this.packages.forEach(p => {
                    if (p.name === next.toString()) {
                        valid = false;
                    }
                });
            } while (!valid);
            return next.toString();
        },
        selectView(option) {
            this.$router.push({name: option.routename, params: {id: this.data.id}});
        },
        setData(data) {
            if (data.data) {
                this.data = data.data;
                if (this.data.packages) {
                    let tmp = JSON.parse(JSON.stringify(this.data.packages));
                    tmp.forEach(function (value, i) {
                        value.id = i;
                    });
                    this.packages = tmp;
                } else {
                    this.packages = null;
                }
            } else {
                this.data = null;
            }
            this.actions = data.actions;
            this.fields = data.fields;
            this.checkLayout();
        },
        updateData() {
            this.$http.put(this.api + '/update/' + this.data.id, {packages: this.packages}, {}).then((res) => {
                this.setData(res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("PickUpReceipt:updateData():error:", error);
            });
        },
        updatePackage(index) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.t("translations.Package");
            this.recordDialogParameters.api = this.api + '/update_package/' + this.data.id;
            this.recordDialogParameters.fields = this.fields.packageFields;
            this.recordDialogParameters.data = this.packages[index];
            this.showRecordDialog = true;
        },
    }
}
</script>

<style scoped>
</style>
